<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh"
                           class="hidden-sm-only hidden-sm-only">刷新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="设备清单列表"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入类型名称" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data"  stripe size="small" v-loading="loading" width="100%" height="100%"
                          border>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="deviceName" label="设备名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="deviceTypeName" label="设备类型" width="" show-overflow-tooltip/>
                    <el-table-column prop="hutName" label="小屋名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="hutId" label="序列号" width="" show-overflow-tooltip/>
                    <el-table-column prop="createTime" label="创建日期" width="" show-overflow-tooltip/>
                    <el-table-column align="center" width="260" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" plain size="mini" @click="handleUpdate(scope.row)">
                                编辑详情
                            </el-button>
                            <el-button type="text" plain size="mini" @click="handleUpdate(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="vmodel"></data-form>
    </div>
</template>

<script>
    import dataForm from './widgets/dataform'

    export default {
        name: 'weijizhi',
        path: '/main/xiaowu/weijizhi',
        components: {
            dataForm,
        },
        data() {
            return {
                loading: false,
                search: {},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                dialogVisible: false,
                form: {},
                isFullIdCard: []
            }
        },
        methods: {
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
            },
            async getData() {
                this.dialogVisible = false
                try {
                    this.$http.get(`/xiaowu/device?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                        }).catch(err => {
                        console.log(err)
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
